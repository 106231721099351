.container{

}

.container .text{
    position: relative;
    color: #4070f4;
    font-size: 30px;
    font-weight: 600;
}

.container .text.first-text{
    color: #fff;
}

.text.sec-text::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #010718;
    border-left: 2px solid #4070f4;
    animation: animate 4s steps(12) infinite;
}
@keyframes animate{
    40%, 60%{
        left: 100%;
    }
    100%{
        left: 0%;
    }
}
.imageslider{
    
    width: 80%;
    font-size: 50px;
    
    
    position:relative;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);

    
}

.imagecaption{
    color: white;
    font-weight: 900;
    background: rgba(8, 27, 41, 0.904);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(8, 27, 41, 0.719);
    backdrop-filter: blur(1.4px);
    -webkit-backdrop-filter: blur(1.4px);
    border: 1px solid rgba(255, 255, 255, 0.54);
    padding-top: 25px;
    padding-bottom: 25px;
    margin-left: 70px;
    margin-right: 70px;
}
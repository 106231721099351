.wrapper{
    display: flex;
    align-items: center;
    height: 80vh
}

.wrapper .first-text{
    font-size: 60px;
    color: #fff;
}

.wrapper .sec-texts{
    height: 90px;
    line-height: 90px;
    overflow: hidden;
}

.sec-texts li{
    position: relative;
    top: 0;
    color: #0ef;
    font-size: 60px;
    list-style: none;
    padding: 0 20px;
    text-shadow: 0 0 10px #0ef;
    animation: slide 12s steps(4) infinite, colorful 6s infinite;
}
@keyframes slide{
    100%{
        top:-360px;
    }
}

@keyframes colorful{
    100%{
        filter: hue-rotate(360deg);
    }
}

.sec-texts li span{
    position: relative;
}

.sec-texts li span::before{
    content: '';
    position: absolute;
    width: 400px;
    left: 0;
    height: 100%;
    background: #081b29;
    border-left: 2px solid #0ef;
    animation: typing 1.5s steps(10) infinite alternate;
}


@keyframes typing{
    100%{
        left: 100%;
        margin: 0 -35px 0 35px;
    }
}

.wrapper .profile-container {
    position: relative;
    width: 400px; /* Adjust the width and height to fit your needs */
    height: 400px;
  }

  .profile-image {
    width: 100%;
    height: auto;
    border-radius: 50px;
    filter: grayscale(100%);
    transition-duration: .5s;

  }

  .profile-image:hover {
    filter: none;
    scale: 105%;
  }


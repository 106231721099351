
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
}
body{
    background: #081b29;
    color: #ededed;
}
:root{
    --mainColor:##081b29;
    --mainColorLight:#5767aa;
    --secondaryColor:#db2b39;
    --textColor:#eee;
}
header{
    display:flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    top: 0;
    left: 0;
    padding: 20px;
    background-color: var(--mainColor);
    color: var(--textColor);
}
.container{
    position: absolute;
    top: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.container .header{
    display: flex;
    align-items: center;
}

.header .icon{
    border-left: 6px solid #00abf0;
    height: 100px;
    border-width: 15px;
    margin-right: 20px;
}

.header .topic{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   
}

.container .content{
    margin-top: 30px;
    font-size: 20px;
    width: 70%;
}
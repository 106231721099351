.logo{
    padding-top: 20px;
    font-size: 25px;
    color: #ededed;
    text-decoration: none;
    font-weight: 600;
    text-shadow: 0 0 10px #0ef;
}

.navbar a{
    /* padding-top: 20px;
    font-size: 20px;
    color: #ededed;
    text-decoration: none;
    font-weight: 600;
    margin-left: 35px;
    transition: .3s; */

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100%;
    background: transparent;
    border: 2px solid #00abf0;
    border-radius: 8px;
    font-size: 19px;
    text-decoration: none;
    font-weight: 600;

    
}


.navbar a:hover,.navbar a.active{
    color: #00abf0;
}

.home{
    margin-top: 100px;
    display: flex;
    align-items: center;
    padding: 0 10%;
}
.home-content{
    max-width: 600px;
}

.home-content h1{
    font-size: 56px;
    font-weight: 700;
    line-height: 1.2;
}

.home-content h3{
    font-size: 32px;
    font-weight: 700;
    
}

.home-content p{
    font-size: 16px;
    margin: 20px 0 40px;
    
}

.home-content .btn-box{
    width: 345px;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.btn-box a{
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100%;
    background: #00abf0;
    border: 2px solid #00abf0;
    border-radius: 8px;
    font-size: 19px;
    color: #081b29;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
    z-index: 1;
}

.btn-box a::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: rgb(30, 128, 239);
    z-index: -1;
    transform: .5s;
}

.btn-box a:hover::before{
    width: 100%;

}

.btn-box a:nth-child(2){
    background: transparent;
    color: #00abf0;
}



.wrap{
    display: flex;
}


.wrap .sec-text{
    margin-left: px;
    height: 90px;
    line-height: 90px;
    overflow: hidden;
}

.sec-text li{
    font-size: 32px;
    font-weight: 700;
    position: relative;
    top: 0;
    color: #0ef;
    font-size: 32px;
    list-style: none;
    padding: 0 20px;
    text-shadow: 0 0 10px #0ef;
    animation: slide 12s steps(4) infinite, colorful 6s infinite;
}
@keyframes slide{
    100%{
        top:-360px;
    }
}

@keyframes colorful{
    100%{
        filter: hue-rotate(360deg);
    }
}

.sec-text li span{
    position: relative;
}

.sec-text li span::before{
    content: '';
    position: absolute;
    width: 400px;
    left: 0;
    height: 200%;
    background: transparent;
    border-left: 3px solid #0ef;
    animation: typing 1.5s steps(10) infinite alternate;
}


@keyframes typing{
    100%{
        left: 100%;
        margin: 0 -35px 0 35px;
    }
}



